<template>
  <div class="contant">
    <div class="flex flex_b">
      <div class="addTile">编辑岗位</div>
    </div>
    <div>
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="部门名称">
          <el-input disabled v-model="form.deptName" placeholder="部门名称"></el-input>
        </el-form-item>
        <el-form-item label="职位">
          <el-input v-model="form.postName" placeholder="职位"></el-input>
        </el-form-item>
        <!-- <el-form-item label="职位">
          <el-select v-model="form.postId" placeholder="请选择职位">
            <el-option v-for="(item, index) in deptList" :key="index" :label="item.postName" :value="item.id"></el-option>
          </el-select>
        </el-form-item> -->
      </el-form>
      <div class="btnSave" @click="edit">
        确定
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'newStaff',
  data() {
    return {
      deptList: [],
      form: {
        deptName: '',
        id: '',
        postId:'',
        postName: ''
      },
      id: '',
    }
  },
  created() {
    let data = JSON.parse(this.$route.query.data)
    this.form = data
    this.getDept()
  },
  methods: {
     // 获取职位列表
    getDept() {
      let _that = this
      let data = {
        id: this.form.deptId,
      }
      _that.$api.deptPost(data).then((res) => {
        if (res.data.code == 200) {
          _that.deptList = res.data.data
        } else {
          _that.utils.error(res.data.message)
        }
      })
    },
    edit() {
      let _that = this
      let data = {
        postName: this.form.postName,
        id: this.form.id
      }
      _that.$api.deptUpt(data).then((res) => {
        if (res.data.code == 200) {
          _that.$router.push({
            path: '/department/department',
          })
          _that.utils.success('编辑成功')
        } else {
          this.utils.error(res.data.message)
        }
      })
    },
  },
}
</script>
<style lang="less" scoped>
.addZw {
  width: 20px;
  height: 20px;
  line-height: 19px;
  border-radius: 50%;
  font-size: 16px;
  color: #fff;
  background: #f39517;
  text-align: center;
  font-weight: bold;
  margin-left: 10px;
  cursor: pointer;
  user-select: none;
}
</style>
